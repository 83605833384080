import faviconB2b from 'img/favicon-b2b.svg?url';
import faviconB2bCloud from 'img/favicon-b2b_cloud.svg?url';
import portalMenuLogoCloud from 'img/portal-menu/portal-menu__logo_cloud.svg?url';
import portalMenuLogoVkWorkdisk from 'img/portal-menu/portal-menu__logo_vk-workdisk.svg?url';
import { BUILD_URLS, ERROR_PAGE_STATUS, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';

const getAppTitle = (isTarm) => {
    if (isTarm) {
        return 'Облако';
    }

    return 'VK WorkDisk';
};

const getAppLogo = (isTarm) => {
    if (isTarm) {
        return portalMenuLogoCloud;
    }

    return portalMenuLogoVkWorkdisk;
};

const getAppFavicon = (isTarm) => {
    if (isTarm) {
        return { appFaviconSVG: faviconB2bCloud, appFaviconICO: `${BUILD_URLS.img}favicon-b2b_cloud.ico` };
    }

    return { appFaviconSVG: faviconB2b, appFaviconICO: `${BUILD_URLS.img}favicon-b2b.ico` };
};

export const applyB2BRebranding = (isTarm) => {
    const appTitle = getAppTitle(isTarm);
    const titleNode = document.querySelector('head > title');
    if (titleNode && !IS_PUBLIC) {
        titleNode.innerHTML = `${ERROR_PAGE_STATUS} / ${appTitle}`;
    }

    const appFavicon = getAppFavicon(isTarm);
    const headNode = document.querySelector('head');
    const svgLink = document.createElement('link');
    svgLink.setAttribute('rel', 'icon');
    svgLink.setAttribute('type', 'image/svg+xml');
    svgLink.href = appFavicon.appFaviconSVG;
    headNode?.append(svgLink);

    const icoLink = document.createElement('link');
    icoLink.setAttribute('rel', 'icon');
    icoLink.setAttribute('sizes', 'any');
    icoLink.href = appFavicon.appFaviconICO;
    headNode?.append(icoLink);

    const appLogo = getAppLogo(isTarm);
    const logoWrapperNode = document.querySelector('#portal-menu a');
    if (logoWrapperNode) {
        logoWrapperNode.setAttribute('title', appTitle);
    }
    const logoNode = logoWrapperNode?.querySelector('img');
    if (logoNode) {
        logoNode.src = appLogo;
        logoWrapperNode.style.visibility = 'visible';
    }
};
